<template>
  <sims-layout>
    <div class="sims-guidance">
      <!-- Loading -->
      <div class="loader" v-if="isLoadingCombined">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No guidance packs -->
      <a-alert v-if="!isLoadingCombined && guidancePacks.length == 0" type="info" message="No guidance packs to show" />
      <!-- / No guidance packs -->

      <!-- Loaded -->
      <a-row :gutter="20" v-if="!isLoadingCombined && guidancePacks.length">
        <a-col :span="colspan" v-for="guidancePack in guidancePacksSorted" :key="guidancePack.id">
          <guidance-pack-card @selected="() => navigateToGuidancePack(guidancePack)" :guidance-pack="guidancePack">
          </guidance-pack-card>
        </a-col>
      </a-row>
      <!-- / Loaded -->
    </div>
  </sims-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import simsLayout from "./simsLayout.vue";
import GuidancePackCard from "../Guidance/GuidancePackCard.vue";
export default {
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  created() {
    this.loadGuidancePacks();
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  components: { simsLayout, GuidancePackCard },
  computed: {
    ...mapGetters("missionControl", {
      isLoadingMissionControl: "isLoading",
    }),

    ...mapGetters("guidance", {
      guidancePacks: "guidancePacks",
      guidancePacksSorted: "guidancePacksSorted",
      isLoading: "isLoading",
    }),

    colspan() {
      if (this.windowWidth > 1200) {
        return 8;
      }
      if (this.windowWidth > 800) {
        return 12;
      }
      if (this.windowWidth > 500) {
        return 24;
      }
      return 24;
    },

    isLoadingCombined() {
      return this.isLoading || this.isLoadingMissionControl;
    }
  },
  methods: {
    ...mapActions("guidance", {
      loadGuidancePacks: "loadGuidancePacks",
      loadLastViews: "loadLastViews",
    }),

    navigateToGuidancePack(guidancePack) {
      this.$router.push(
        "/sims/guidance/" +
        guidancePack["$v"]["tenantId"] +
        "/" +
        guidancePack["$v"]["id"]
      );
    },
  },
};
</script>

<style scoped lang="scss">
.guidance-packs-list-wrapper {
  padding-bottom: 30px;
}

.guidance-pack-card-wrapper {
  margin-bottom: 20px;
}
</style>